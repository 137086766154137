"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QueryEventCategoryMasterDataList = exports.GetEventCategoryMasterDataDetail = exports.GetAllEventCategoryMasterDataList = exports.DeleteEventCategoryMasterData = exports.UpdateEventCategoryMasterData = exports.CreateEventCategoryMasterData = exports.EventCategoryMasterDataRefPathOrderByTimestamp = exports.EventCategoryMasterDataRefPath = void 0;

var firestore_1 = require("rxfire/firestore");

var rxjs_1 = require("rxjs");

var firebase_1 = require("../firebase");

exports.EventCategoryMasterDataRefPath = function () {
  return firebase_1.FirebaseApp.firestore().collection('EventCategoryMasterData');
};

exports.EventCategoryMasterDataRefPathOrderByTimestamp = function () {
  return exports.EventCategoryMasterDataRefPath().orderBy('EventCategoryMasterDataTimestamp', 'desc');
};

exports.CreateEventCategoryMasterData = function (Data) {
  return rxjs_1.from(exports.EventCategoryMasterDataRefPath().add(Data));
};

exports.UpdateEventCategoryMasterData = function (EventCategoryMasterDataKey, Data) {
  return rxjs_1.from(exports.EventCategoryMasterDataRefPath().doc(EventCategoryMasterDataKey).set(__assign(__assign({}, Data), {
    EventUpdateTimestamp: firebase_1.FirestoreFieldValue.serverTimestamp()
  }), {
    merge: true
  }));
};

exports.DeleteEventCategoryMasterData = function (EventCategoryMasterDataKey) {
  return rxjs_1.from(exports.EventCategoryMasterDataRefPath().doc(EventCategoryMasterDataKey).delete());
};

exports.GetAllEventCategoryMasterDataList = function (limit) {
  var RefPath = limit ? exports.EventCategoryMasterDataRefPathOrderByTimestamp().limit(limit) : exports.EventCategoryMasterDataRefPathOrderByTimestamp();
  return firestore_1.collectionData(RefPath, 'EventCategoryMasterDataKey');
};

exports.GetEventCategoryMasterDataDetail = function (EventCategoryMasterDataKey) {
  return firestore_1.docData(exports.EventCategoryMasterDataRefPath().doc(EventCategoryMasterDataKey), 'EventCategoryMasterDataKey');
};

exports.QueryEventCategoryMasterDataList = function (QueryField, QueryOperator, QueryValue) {
  return firestore_1.collectionData(exports.EventCategoryMasterDataRefPath().where(QueryField, QueryOperator, QueryValue), 'EventCategoryMasterDataKey');
};