"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GetUserExhibitor = exports.DeleteAllEventExhibitor = exports.QueryEventExhibitorList = exports.GetEventExhibitorDetail = exports.GetAllEventExhibitorList = exports.DeleteEventExhibitor = exports.UpdateEventExhibitor = exports.CreateEventExhibitor = void 0;

var firestore_1 = require("rxfire/firestore");

var rxjs_1 = require("rxjs");

var operators_1 = require("rxjs/operators");

var firebase_1 = require("../firebase");

var event_1 = require("./event");

var firebase_2 = require("firebase");

var EventExhibitorRefPath = function EventExhibitorRefPath(EventKey) {
  return firebase_1.FirebaseApp.firestore().collection('Event').doc(EventKey).collection('EventExhibitor');
};

exports.CreateEventExhibitor = function (EventKey, Data) {
  var batch = firebase_1.FirebaseApp.firestore().batch();
  var exhibitor = EventExhibitorRefPath(EventKey).doc();
  batch.set(exhibitor, __assign(__assign({}, Data), {
    EventExhibitorCreateTimestamp: firebase_2.firestore.FieldValue.serverTimestamp()
  }), {
    merge: true
  });
  batch.set(event_1.EventRefPath().doc(EventKey), {
    EventExhibitorList: firebase_1.FirestoreFieldValue.arrayUnion(exhibitor.id)
  }, {
    merge: true
  });
  batch.commit();
  return firestore_1.docData(exhibitor, 'EventExhibitorKey');
};

exports.UpdateEventExhibitor = function (EventKey, EventExhibitorKey, Data) {
  return rxjs_1.from(EventExhibitorRefPath(EventKey).doc(EventExhibitorKey).set(__assign(__assign({}, Data), {
    EventExhibitorUpdateTimestamp: firebase_1.FirestoreFieldValue.serverTimestamp()
  }), {
    merge: true
  }));
};

exports.DeleteEventExhibitor = function (EventKey, EventExhibitorKey, UserKey) {
  return rxjs_1.combineLatest(rxjs_1.from(EventExhibitorRefPath(EventKey).doc(EventExhibitorKey).delete()), event_1.UpdateEvent(EventKey, {
    EventExhibitorList: firebase_1.FirestoreFieldValue.arrayRemove(UserKey)
  }));
};

exports.GetAllEventExhibitorList = function (EventKey, sort) {
  if (sort === void 0) {
    sort = 'desc';
  }

  return firestore_1.collectionData(EventExhibitorRefPath(EventKey).orderBy('EventExhibitorCreateTimestamp', sort), 'EventExhibitorKey');
};

exports.GetEventExhibitorDetail = function (EventKey, EventExhibitorKey) {
  return firestore_1.docData(EventExhibitorRefPath(EventKey).doc(EventExhibitorKey), 'EventExhibitorKey');
};

exports.QueryEventExhibitorList = function (EventKey, QueryField, QueryOperator, QueryValue) {
  return firestore_1.collectionData(EventExhibitorRefPath(EventKey).where(QueryField, QueryOperator, QueryValue), 'EventExhibitorKey');
};

exports.DeleteAllEventExhibitor = function (EventKey) {
  return exports.GetAllEventExhibitorList(EventKey).pipe(operators_1.map(function (EventExhibitorList) {
    var DeleteAllEventExhibitorListBatch = firebase_1.FirebaseApp.firestore().batch();
    EventExhibitorList.map(function (EventExhibitorDoc) {
      return DeleteAllEventExhibitorListBatch.delete(EventExhibitorRefPath(EventKey).doc(EventExhibitorDoc.EventExhibitorKey));
    });
    return DeleteAllEventExhibitorListBatch.commit();
  }));
};

exports.GetUserExhibitor = function (UserKey) {
  return firestore_1.collectionData(event_1.EventRefPath().where('EventExhibitorList', 'array-contains', UserKey), 'EventKey');
};