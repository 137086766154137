"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CombineCreateRegisterUserAuthWithCreateUserInfo = exports.QueryUserInfoList = exports.GetUserInfoDetail = exports.GetAllUserInfoList = exports.DeleteUserInfo = exports.UpdateUserInfo = exports.CreateUserInfo = void 0;

var firestore_1 = require("rxfire/firestore");

var rxjs_1 = require("rxjs");

var firebase_1 = require("../firebase");

var userTicket_1 = require("./userTicket");

var firebase_2 = require("firebase");

var auth_1 = require("@manow/auth");

var operators_1 = require("rxjs/operators");

var UserInfoRefPath = function UserInfoRefPath() {
  return firebase_1.FirebaseApp.firestore().collection('UserInfo');
};

exports.CreateUserInfo = function (Data) {
  return rxjs_1.from(UserInfoRefPath().add(__assign(__assign({}, Data), {
    UserInfoRegistrationTimestamp: firebase_2.firestore.FieldValue.serverTimestamp()
  })));
};

exports.UpdateUserInfo = function (UserKey, Data) {
  return rxjs_1.from(UserInfoRefPath().doc(UserKey).set(__assign(__assign({}, Data), {
    UserInfoUpdateTimestamp: firebase_1.FirestoreFieldValue.serverTimestamp()
  }), {
    merge: true
  }));
};

exports.DeleteUserInfo = function (UserKey) {
  return rxjs_1.combineLatest(rxjs_1.from(UserInfoRefPath().doc(UserKey).delete()), userTicket_1.DeleteAllUserTicket(UserKey));
};

exports.GetAllUserInfoList = function () {
  return firestore_1.collectionData(UserInfoRefPath().orderBy('UserInfoRegistrationTimestamp', 'desc'), 'UserKey');
};

exports.GetUserInfoDetail = function (UserKey) {
  return firestore_1.docData(UserInfoRefPath().doc(UserKey), 'UserKey');
};

exports.QueryUserInfoList = function (QueryField, QueryOperator, QueryValue) {
  return firestore_1.collectionData(UserInfoRefPath().where(QueryField, QueryOperator, QueryValue), 'UserKey');
};

exports.CombineCreateRegisterUserAuthWithCreateUserInfo = function (AuthData, UserInfoData) {
  return rxjs_1.from(auth_1.client.register(AuthData)).pipe(operators_1.map(function (RegisterResult) {
    return RegisterResult.user.uid;
  }), operators_1.switchMap(function (Uid) {
    exports.UpdateUserInfo(Uid, UserInfoData);
    return Uid;
  }));
};