"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CreateEventWithEventTicket = exports.QueryEventList = exports.GetEventDetail = exports.GetAllEvent = exports.GetAllEventList = exports.DeleteEvent = exports.UpdateEvent = exports.CreateEvent = exports.EventRefPath = void 0;

var firestore_1 = require("rxfire/firestore");

var rxjs_1 = require("rxjs");

var operators_1 = require("rxjs/operators");

var firebase_1 = require("../firebase");

var eventTicket_1 = require("./eventTicket");

exports.EventRefPath = function () {
  return firebase_1.FirebaseApp.firestore().collection('Event');
};

exports.CreateEvent = function (Data) {
  var payload = Data;
  var currentUser = firebase_1.FirebaseApp.auth().currentUser;

  if (currentUser) {
    payload = __assign(__assign({}, payload), {
      UserKey: currentUser.uid
    });
  }

  return rxjs_1.from(exports.EventRefPath().add(payload));
};

exports.UpdateEvent = function (EventKey, Data) {
  return rxjs_1.from(exports.EventRefPath().doc(EventKey).set(__assign(__assign({}, Data), {
    EventUpdateTimestamp: firebase_1.FirestoreFieldValue.serverTimestamp()
  }), {
    merge: true
  }));
};

exports.DeleteEvent = function (EventKey) {
  return exports.UpdateEvent(EventKey, {
    Privacy: 'delete',
    EventDeleteTimestamp: firebase_1.FirestoreFieldValue.serverTimestamp()
  });
};

exports.GetAllEventList = function (limit, query) {
  if (limit === void 0) {
    limit = 0;
  }

  if (query === void 0) {
    query = function query(ref) {
      return ref;
    };
  }

  var myRef = exports.EventRefPath().limit(limit);
  myRef = query(myRef);
  return firestore_1.collectionData(myRef, 'EventKey');
};

exports.GetAllEvent = function () {
  firestore_1.collectionData(exports.EventRefPath(), 'EventKey').subscribe(function (e) {
    console.log('GetAllEvent', e);
  });
  return firestore_1.collectionData(exports.EventRefPath(), 'EventKey');
};

exports.GetEventDetail = function (EventKey) {
  return firestore_1.docData(exports.EventRefPath().doc(EventKey), 'EventKey');
};

exports.QueryEventList = function (QueryField, QueryOperator, QueryValue) {
  return firestore_1.collectionData(exports.EventRefPath().where(QueryField, QueryOperator, QueryValue), 'EventKey');
};

exports.CreateEventWithEventTicket = function (EventData, EventTicketList) {
  return exports.CreateEvent(EventData).pipe(operators_1.switchMap(function (EventResult) {
    return rxjs_1.combineLatest.apply(void 0, EventTicketList.map(function (EventTicketData) {
      return eventTicket_1.CreateEventTicket(EventResult.id, __assign(__assign({}, EventTicketData), {
        EventTicketCreateTimestamp: firebase_1.FirestoreFieldValue.serverTimestamp(),
        EventKey: EventResult.id
      }));
    }));
  }));
};