"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GetUserLikeEventDetail = exports.GetUserLikeEventList = exports.DeleteAllUserLikeEvent = exports.DeleteUserLikeEvent = exports.UpdateUserLikeEvent = exports.CreateUserLikeEvent = void 0;

var firestore_1 = require("rxfire/firestore");

var rxjs_1 = require("rxjs");

var operators_1 = require("rxjs/operators");

var firebase_1 = require("firebase");

var firebase_2 = require("../firebase");

var UserLikeEventRefPath = function UserLikeEventRefPath(UserKey) {
  return firebase_2.FirebaseApp.firestore().collection('UserInfo').doc(UserKey).collection('UserLikeEvent');
};

exports.CreateUserLikeEvent = function (UserKey, Data) {
  return rxjs_1.from(UserLikeEventRefPath(UserKey).add(__assign(__assign({}, Data), {
    UserLikeEventCreateTimestamp: firebase_1.firestore.FieldValue.serverTimestamp()
  })));
};

exports.UpdateUserLikeEvent = function (UserKey, UserLikeEventKey, Data) {
  return rxjs_1.from(UserLikeEventRefPath(UserKey).doc(UserLikeEventKey).set(__assign(__assign({}, Data), {
    UserLikeEventUpdateTimestamp: firebase_2.FirestoreFieldValue.serverTimestamp()
  }), {
    merge: true
  }));
};

exports.DeleteUserLikeEvent = function (UserKey, UserLikeEventKey) {
  return rxjs_1.from(UserLikeEventRefPath(UserKey).doc(UserLikeEventKey).delete());
};

exports.DeleteAllUserLikeEvent = function (UserKey) {
  return firestore_1.collectionData(UserLikeEventRefPath(UserKey), 'UserLikeEventKey').pipe(operators_1.map(function (UserLikeEventList) {
    var DeleteAllBatch = firebase_2.FirebaseApp.firestore().batch();
    UserLikeEventList.map(function (doc) {
      return DeleteAllBatch.delete(UserLikeEventRefPath(UserKey).doc(doc.UserLikeEventKey));
    });
    return DeleteAllBatch.commit();
  }));
};

exports.GetUserLikeEventList = function (UserKey) {
  return firestore_1.collectionData(UserLikeEventRefPath(UserKey).orderBy('UserLikeEventCreateTimestamp', 'desc'), 'UserLikeEventKey').pipe(operators_1.take(1));
};

exports.GetUserLikeEventDetail = function (UserKey, UserLikeEventKey) {
  return firestore_1.docData(UserLikeEventRefPath(UserKey).doc(UserLikeEventKey), 'UserLikeEventKey');
};