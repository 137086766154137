"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GetOrganizerEvent = exports.GetUserOrganizer = exports.DeleteAllOrganizerMember = exports.GetOrganizerMemberDetail = exports.GetAllOrganizerMember = exports.DeleteOrganizerMember = exports.UpdateOrganizerMember = exports.CreateOrganizerMember = exports.QueryUserInfoList = exports.GetOrganizerDetail = exports.GetAllOrganizerList = exports.DeleteOrganizer = exports.UpdateOrganizer = exports.CreateOrganizerWithMember = exports.CreateOrganizer = void 0;

var firestore_1 = require("rxfire/firestore");

var rxjs_1 = require("rxjs");

var operators_1 = require("rxjs/operators");

var firebase_1 = require("../firebase");

var event_1 = require("../event/event");

var OrganizerRefPath = function OrganizerRefPath() {
  return firebase_1.FirebaseApp.firestore().collection('Organizer');
};

var OrganizerMemberRefPath = function OrganizerMemberRefPath(OrganizerKey) {
  return firebase_1.FirebaseApp.firestore().collection('Organizer').doc(OrganizerKey).collection('OrganizerMember');
};

exports.CreateOrganizer = function (OrganizerData) {
  return rxjs_1.from(OrganizerRefPath().add(OrganizerData));
};

exports.CreateOrganizerWithMember = function (OrganizerData, OrganizerMemberData) {
  return rxjs_1.from(OrganizerRefPath().add(OrganizerData)).pipe(operators_1.switchMap(function (DocData) {
    return OrganizerMemberData.map(function (OrganizerMemberDoc) {
      exports.CreateOrganizerMember(DocData.id, OrganizerMemberDoc);
    });
  }));
};

exports.UpdateOrganizer = function (OrganizerKey, Data) {
  return rxjs_1.from(OrganizerRefPath().doc(OrganizerKey).set(__assign(__assign({}, Data), {
    OrganizerUpdateTimestamp: firebase_1.FirestoreFieldValue.serverTimestamp()
  }), {
    merge: true
  }));
};

exports.DeleteOrganizer = function (OrganizerKey) {
  return rxjs_1.combineLatest(rxjs_1.from(OrganizerRefPath().doc(OrganizerKey).delete()), exports.DeleteAllOrganizerMember(OrganizerKey));
};

exports.GetAllOrganizerList = function () {
  return firestore_1.collectionData(OrganizerRefPath().orderBy('OrganizerCreateTimestamp', 'desc'), 'OrganizerKey');
};

exports.GetOrganizerDetail = function (OrganizerKey) {
  return firestore_1.docData(OrganizerRefPath().doc(OrganizerKey), 'OrganizerKey');
};

exports.QueryUserInfoList = function (QueryField, QueryOperator, QueryValue) {
  return firestore_1.collectionData(OrganizerRefPath().where(QueryField, QueryOperator, QueryValue), 'OrganizerKey');
};

exports.CreateOrganizerMember = function (OrganizerKey, Data) {
  return rxjs_1.from(OrganizerMemberRefPath(OrganizerKey).add(Data)).pipe(operators_1.switchMap(function (OrganizerMemberRes) {
    return exports.UpdateOrganizer(OrganizerKey, {
      abc: OrganizerMemberRes.id
    });
  })).subscribe(function (_) {});
};

exports.UpdateOrganizerMember = function (OrganizerKey, OrganizerMemberUserKey, Data) {
  return rxjs_1.from(OrganizerMemberRefPath(OrganizerKey).doc(OrganizerMemberUserKey).set(__assign(__assign({}, Data), {
    OrganizerMemberUpdateTimestamp: firebase_1.FirestoreFieldValue.serverTimestamp()
  }), {
    merge: true
  }));
};

exports.DeleteOrganizerMember = function (OrganizerKey, OrganizerMemberUserKey) {
  return rxjs_1.from(OrganizerMemberRefPath(OrganizerKey).doc(OrganizerMemberUserKey).delete());
};

exports.GetAllOrganizerMember = function (OrganizerKey) {
  return firestore_1.collectionData(OrganizerMemberRefPath(OrganizerKey), 'OrganizerKey');
};

exports.GetOrganizerMemberDetail = function (OrganizerKey, OrganizerMemberKey) {
  return firestore_1.docData(OrganizerMemberRefPath(OrganizerKey).doc(OrganizerMemberKey), 'OrganizerKey');
};

exports.DeleteAllOrganizerMember = function (OrganizerKey) {
  return exports.GetAllOrganizerMember(OrganizerKey).pipe(operators_1.map(function (OrganizerMemberList) {
    var DeleteAllOrganizerMemberListBatch = firebase_1.FirebaseApp.firestore().batch();
    OrganizerMemberList.map(function (OrganizerMemberDoc) {
      return DeleteAllOrganizerMemberListBatch.delete(OrganizerMemberRefPath(OrganizerKey).doc(OrganizerMemberDoc.OrganizerKey));
    });
    return DeleteAllOrganizerMemberListBatch.commit();
  }));
};

exports.GetUserOrganizer = function (UserKey) {
  return firestore_1.collectionData(OrganizerRefPath().where('OrganizerMemberList', 'array-contains', UserKey), 'OrganizerKey');
};

exports.GetOrganizerEvent = function (OrganizerKey) {
  return firestore_1.collectionData(event_1.EventRefPath().where('OrganizerKey', '==', OrganizerKey), 'EventKey');
};